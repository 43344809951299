<template>
  <div class="pa-5 gray-0">
    <div class="mb-2 text-body font-weight-light gray-100--text">Workflow Process</div>
    <div class="d-flex align-center">
      <div>
        <UiSelect
          v-model="selectedWorkflow"
          :items="allSchemes"
          :item-text="'text'"
          :item-value="'value'"
          hide-details
          :disabled="!canEdit"
          placeholder="Select workflow"
          @change="changeWorkflow"
          style="width: 100%;"
        >
          <template #append-item>
            <UiBtn @click="$store.dispatch('openModal', 'createWorkflowModal')" text plain width="auto" height="auto"
                   color="accent" class="px-4 rounded-0">
              + Add New Workflow
            </UiBtn>
          </template>
        </UiSelect>
      </div>
      <UiBtn
        width="126"
        color="accent"
        v-if="canEdit"
        :disabled="!scheme?.modules?.length || loading"
        @click="saveAndClose"
        class="ml-auto"
      >
        Save Changes
      </UiBtn>
    </div>

    <v-divider class="mt-4 mb-5"/>


    <div v-if="loading" class="d-flex justify-center mb-4">
      <v-progress-circular
        :size="100"
        color="accent"
        indeterminate/>
    </div>


    <div class="workflow-tables-wrap d-flex space-x-4" v-if="!loading && scheme">
      <div class="workflow-tables-col d-flex flex-column flex-shrink-0 fill-width">
        <div class="d-flex align-center justify-space-between mb-4 text-body font-weight-semi-bold gray-100--text">
          Process Library
          <UiBtn disabled text plain width="auto" height="auto" color="accent" class="px-0 border-b-s rounded-0">
            + Add new module
          </UiBtn>
        </div>
        <div class="workflow-table fill-height fill-width py-6 px-3 rounded">
          <div class="workflow-table-head d-flex mb-4 gray-100--text">
            <div class="text-captions-1">Type of process</div>
          </div>


          <draggable
            :list="modulesDictionary"
            :group="{ name: 'modules', pull: 'clone'}"
            :clone="cloneDictionaryModule"
            handle=".drag-handle"
            :disabled="!canEdit || isDefault"
            @add="dropModuleToDictionary"
            class="fill-height fill-width"
          >
            <div v-for="(module, idx) in modulesDictionary" :key="idx"
                 class="workflow-table-row-item bordered-item d-flex align-center rounded pl-2 pr-3">
              <div :class="[
                  'd-flex mr-2',
                  (scheme.modules.length >= maxSchemeModules || (!canEdit || isDefault)) ? 'gray-10--text' : 'drag-handle gray-30--text cursor-pointer'
                  ]">
                <IconMoveLong width="24"/>
              </div>

              <div class="mr-4 text-captions-1 text-overflow-ellipsis text-no-wrap overflow-hidden gray-60--text">
                {{ module.title }}
              </div>

              <UiBtn outlined icon width="26" height="26" color="accent" class="ml-auto"
                     :disabled="(scheme.modules.length >= maxSchemeModules || (!canEdit || isDefault))"
                     @click="moduleToProcess(module)">
                <IconPlus width="16"/>
              </UiBtn>
            </div>
          </draggable>
        </div>
      </div>

      <div class="workflow-tables-col d-flex flex-column fill-width">

        <div class="d-flex align-center justify-space-between mb-4 text-body font-weight-semi-bold gray-100--text">
          Process Flow
          <UiCheckbox
            v-model="scheme.open_workflow"
            checkbox-form="square"
            hide-details
            :disabled="!canEdit || isDefault"
            class="ma-0 py-0"
          >
            <template #label>
              <div class="d-flex text-body font-weight-light">
                Open Workflow

                <v-tooltip top :nudge-top="4" color="gray-80" max-width="292" content-class="closed-workflow-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" class="d-flex ml-1">
                      <IconInfoCircle width="16" class="accent--text"/>
                    </div>
                  </template>
                  <span class="gray-0--text">
                      By default, a project won’t allow users to jump to the following process until the current one is completed. An open workflow does not have a specific order, and all the processes can be accessed from the beginning.
                    </span>
                </v-tooltip>
              </div>
            </template>
          </UiCheckbox>
        </div>
        <div class="workflow-table d-flex flex-column fill-height fill-width py-6 px-3 rounded">
          <div class="workflow-table-head gray-100--text d-flex mb-4">
            <div class="workflow-table-order text-captions-1">Order</div>
            <div class="fill-width text-captions-1 ml-5">Type of process</div>
            <div class="workflow-table-duration flex-shrink-0 text-captions-1">Duration (days)</div>
          </div>


          <draggable
            :list="scheme.modules"
            group="modules"
            handle=".drag-handle"
            @add="dropModuleToProcess"
            :disabled="!canEdit || isDefault"
            class="relative fill-height fill-width"
          >
            <div v-for="(module, idx) in scheme.modules" :key="module.module_id" class="d-flex space-x-2">
              <div class="workflow-table-order workflow-table-row-item d-flex align-center">
                <div
                  class="workflow-item-index bordered-item d-flex align-center justify-center rounded text-body font-weight-semi-bold">
                  {{ idx + 1 }}
                </div>
              </div>

              <div
                class="workflow-table-row-item grid-item bordered-item d-flex align-center fill-width rounded pl-2 pr-3">
                <div
                  :class="['d-flex mr-2', (canRemove(module) && canEdit && !isDefault) ? 'drag-handle gray-30--text cursor-pointer' : 'gray-10--text']">
                  <IconMoveLong width="24"/>
                </div>

                <div class="d-flex fill-width align-center text-captions-1 gray-60--text flex-auto">
                  <template v-if="!module.showRenameInput">
                    <span class="text-truncate">{{ module.title }}</span>
                    <UiBtn text icon plain width="auto" height="auto" v-if="canEdit && !isDefault" class="ml-auto"
                           @click="module.showRenameInput = true">
                      <IconPencil width="14" class="gray-60--text"/>
                    </UiBtn>
                  </template>

                  <UiChangeNameInput
                    v-else
                    v-model="module.newTitle"
                    placeholder="Enter name"
                    :rules="'required|min:3|max:128'"
                    :disabled="!canEdit || isDefault"
                    @onSave="module.title = module.newTitle; module.showRenameInput = false"
                    @onClose="module.showRenameInput = false"
                    class="my-1 fill-width"
                  />
                </div>

                <div class="mx-4 d-flex space-x-2">
                  <UiSwitch class="workflow-approved" :disabled="!canEdit || isDefault" v-model="module.approve" inset
                            color="#0500FF" hide-details>
                    <template #label>
                      <span class="text-captions-1 gray-60--text">Approved</span>
                    </template>
                  </UiSwitch>
                  <v-tooltip top :nudge-top="4" color="gray-80" max-width="292" content-class="closed-workflow-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="d-flex">
                        <IconInfoCircle width="16" class="gray-60--text"/>
                      </div>
                    </template>
                    <span class="gray-0--text">
                      If this is turned on, the module will not need to be reviewed and approved in order to proceed with next modules
                    </span>
                  </v-tooltip>
                </div>
                <div class="workflow-ttl-input relative ml-auto flex-shrink-0"
                     :style="{'--value-length': module.ttl.toString().length}">
                  <input
                    v-model.lazy="module.ttl" v-money="moduleTtlOptions"
                    @keyup="() => module.ttl = validateTtl(module.ttl)"
                    :disabled="!canEdit || isDefault"
                    @keydown.up="incrementTtl(module)"
                    @keydown.down="decrementTtl(module)"
                  />

                  <UiBtn icon plain width="20" height="10" class="absolute px-0" :disabled="!canEdit || isDefault"
                         @click="incrementTtl(module)">
                    <IconChevronUp width="10"/>
                  </UiBtn>
                  <UiBtn icon plain width="20" height="10" class="absolute px-0" :disabled="!canEdit || isDefault"
                         @click="decrementTtl(module)">
                    <IconChevronDown width="10"/>
                  </UiBtn>
                </div>
              </div>
              <div class="workflow-table-row-item">
                <div class="workflow-item-move-back  bordered-item d-flex align-center justify-center rounded">
                  <UiBtn icon width="44" height="44" color="gray-60"
                         :disabled="!canRemove(module) || (!canEdit || isDefault)"
                         @click="moduleToDictionary(module, idx)">
                    <IconTrash2 width="16"/>
                  </UiBtn>
                </div>
              </div>
            </div>

            <div
              v-if="!scheme.modules.length"
              class="d-flex flex-column justify-center align-center bordered-item absolute inset-0 fill-width rounded accent--text empty-state"
            >
              <IconDotsGridEmpty width="60" class="mb-1"/>
              <p class="mb-0 text-captions-1 text-center" style="max-width: 250px">
                <strong class="font-weight-semi-bold">Drag and drop or use the arrows</strong>
                to move processes from the process library
              </p>
            </div>
          </draggable>
        </div>
      </div>
    </div>

    <CreateWorkflowModal v-if="getModal('createWorkflowModal').isOpen" @onCreate="getSchemesList"/>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Draggable from "vuedraggable";
import {VMoney} from 'v-money'
import UiBtn from "@/components/UI/UiBtn";
import UiSelect from "@/components/UI/UiSelect";
import UiCheckbox from "@/components/UI/UiCheckbox";
import UiChangeNameInput from "@/components/UI/UiChangeNameInput";

import CreateWorkflowModal from "@/components/modals/CreateWorkflowModal"
import UiSwitch from "@/components/UI/UiSwitch.vue";

export default {
  name: 'SpaceWorkflowView',
  directives: {money: VMoney},
  components: {
    UiSwitch,
    IconInfoCircle: () => import('@/components/icons/IconInfoCircle'),
    IconMoveLong: () => import('@/components/icons/IconMoveLong'),
    IconDotsGridEmpty: () => import('@/components/icons/IconDotsGridEmpty'),
    IconPlus: () => import('@/components/icons/IconPlus'),
    IconChevronUp: () => import('@/components/icons/IconChevronUp'),
    IconChevronDown: () => import('@/components/icons/IconChevronDown'),
    IconPencil: () => import('@/components/icons/IconPencil'),
    IconTrash2: () => import('@/components/icons/IconTrash2'),

    Draggable,
    UiBtn,
    UiSelect,
    UiCheckbox,
    UiChangeNameInput,
    CreateWorkflowModal,
  },
  data() {
    return {
      loading: true,
      selectedWorkflow: null,
      defaultModulesDictionary: [],
      modulesDictionary: null,
      maxSchemeModules: 20,
      schemaList: [],
      scheme: {
        modules: [],
      },
      moduleTtlOptions: {
        decimal: "",
        thousands: "",
        suffix: "",
        precision: 0,
        masked: false
      }
    }
  },
  computed: {
    ...mapGetters([
      'getModal',
      'getActiveSpace',
      'getSpaces',
    ]),
    canEdit() {
      return this.getActiveSpace && this.getActiveSpace.currentSpaceUser.user_role.name === this.$config.space.userRole.spaceAdmin
    },
    isDefault() {
      return this.selectedWorkflow === 1
    },
    allSchemes() {
      if (!this.getActiveSpace?.id) return [];
      return this.schemaList.reduce((acc, scheme) => {
        if (scheme.id === 1) {
          acc.push({text: 'Default', value: scheme.id})
        }
        if (scheme.space_id && scheme.space_id === this.getActiveSpace?.id) {
          acc.push({text: scheme.title, value: scheme.id})
        }
        return acc
      }, []);
    },
    selectedSchemeId() {
      return this.getActiveSpace?.scheme_id || null;
    },
    canRemove() {
      return module => {
        // if (this.getProject.id) {
        //   const projectModule = this.getProject.modules.find(pModule => pModule.workflow_scheme_module_id === module.module_id);
        //   return module.workflow_scheme_id === this.getProject.scheme_id &&  projectModule ? projectModule.total_files <= 0 : true;
        // }
        return true;
      }
    }
  },
  watch: {
    selectedSchemeId: {
      handler(nv) {
        if (nv) {
          this.selectedWorkflow = nv;
          this.setWorkflowScheme(nv);
        }

      }
    },
  },
  async created() {
    await this.getSchemesList()
    this.$store.dispatch('setActiveSpace', this.$route.params.id)
    const res = await this.$api.module.list();
    this.defaultModulesDictionary = res.sort((a, b) => a.id > b.id ? 1 : -1).map(module => ({
      ...module,
      ttl: 1,
      module_id: module.id,
      showRenameInput: false,
      approve: false
    }));

    this.modulesDictionary = [...this.defaultModulesDictionary];

    this.selectedWorkflow = this.selectedSchemeId;

    if (this.selectedSchemeId) {
      this.setWorkflowScheme(this.selectedSchemeId);
    }
    this.loading = false;
  },
  methods: {
    ...mapActions([
      'setCategories',
    ]),
    async getSchemesList(schemeId) {
      try {
        const schemasListRes = await this.$api.workflowScheme.list()
        this.schemaList = schemasListRes.map(schema => {
          return {
            ...schema,
            modules: schema.modules.map(module => {
              return {
                ...module,
                slug: module.module.slug,
                type: module.module.type
              }
            })
          }
        })
        if (schemeId) {
          this.selectedWorkflow = schemeId;
          this.setWorkflowScheme(schemeId);
        }
      } catch (err) {
        console.log('err - ', err)
      }
    },
    setWorkflowScheme(schemeId) {
      this.scheme = this.schemaList.find(scheme => scheme.id === schemeId);
      if (!this.scheme) {
        return
      }
      this.scheme.modules = this.scheme.modules.map(module => {
        return {
          ...module,
          newTitle: module.title,
          showRenameInput: false,
        }
      }).sort((a, b) => a.sort_order > b.sort_order ? 1 : -1);

      if (this.scheme.modules.find(module => module.type === this.$config.project.moduleTypes.moodBoard)) {
        this.modulesDictionary = this.defaultModulesDictionary.filter(module => module.type !== this.$config.project.moduleTypes.moodBoard);
      } else {
        this.modulesDictionary = [...this.defaultModulesDictionary];
      }
    },
    changeWorkflow() {
      this.setWorkflowScheme(this.selectedWorkflow);
    },
    cloneDictionaryModule(module) {
      const modulesId = this.scheme.modules.map(module => module.module_id);
      return {
        ...module,
        module_id: modulesId.length ? Math.max(...modulesId) + 1 : 1,
        original_module_id: module.id,
        workflow_scheme_id: this.selectedSchemeId,
        newTitle: module.title,
        sort_order: 0,
      };
    },
    moduleToProcess(targetModule) {
      if (targetModule.type === this.$config.project.moduleTypes.moodBoard) {
        this.modulesDictionary = this.modulesDictionary.filter(module => module.id !== targetModule.module_id);
      }

      this.scheme.modules.push(this.cloneDictionaryModule(targetModule));
    },
    dropModuleToProcess() {
      if (this.scheme.modules.find(module => module.type === this.$config.project.moduleTypes.moodBoard)) {
        this.modulesDictionary = this.modulesDictionary.filter(module => module.type !== this.$config.project.moduleTypes.moodBoard);
      }
    },
    moduleToDictionary(targetModule, idx) {
      // this.scheme.modules = this.scheme.modules.filter(module => module.module_id !== targetModule.module_id); // TODO fix on BE
      this.scheme.modules = this.scheme.modules.filter((module, ind) => ind !== idx);

      if (targetModule.type === this.$config.project.moduleTypes.moodBoard) {
        this.modulesDictionary.push({
          ...targetModule,
          id: targetModule.module_id,
          ttl: 1,
          showRenameInput: false,
        });
      }
    },
    dropModuleToDictionary() {
      const uniqueModules = this.modulesDictionary.reduce((acc, module) => {
        acc[module.slug] = module;
        return acc;
      }, {});
      this.modulesDictionary = Object.values(uniqueModules);
    },
    getFormattedScheme() {
      return {
        title: this.scheme.title,
        // category: Array.isArray(this.getProject.category) ? this.getProject.category[0].id : this.getProject.category,
        category: this.scheme.category[0].id,
        open_workflow: this.scheme.open_workflow,
        space_id: this.scheme.space_id,
        scheme_design: this.scheme.modules.map((module, idx) => ({
          ttl: this.formatTtl(module.ttl),
          module_id: module.module_id,
          approve: module.approve,
          original_module_id: module.original_module_id || module.module_id,
          title: module.title,
          icon: module.icon,
          type: module.type,
          sort_order: idx + 1,
          from: 'from' in module ? module.from : [],
          to: 'to' in module ? module.to : [],
          parent_id: 'parent_id' in module ? module.parent_id : 0,
        }))
      };
    },
    async updateScheme() {
      if (this.isDefault) return this.selectedWorkflow
      try {
        const scheme = {...this.getFormattedScheme()};
        await this.$api.workflowScheme.update(this.scheme.id, {...scheme});
        return this.selectedWorkflow;
      } catch (error) {
        console.error(error);
      }
    },
    async saveAndClose() {
      if (!this.scheme.modules.length) {
        this.$toast.open({
          message: `Select modules!`,
          type: 'error',
          position: 'top-right'
        });
        return
      }
      if (this.scheme.modules.every(el => el.slug === this.$config.project.modules.moodBoard)) {
        this.$toast.open({
          message: `White board can't be single module`,
          type: 'error',
          position: 'top-right'
        });
        return
      }

      this.loading = true;

      const schemeId = await this.updateScheme();

      const spaceUpdated = await this.$api.spaces.update(this.getActiveSpace?.id, {
        title: this.getActiveSpace.title,
        color: this.getActiveSpace.color,
        scheme_id: schemeId
      })
      const updatedSpaces = this.getSpaces.map(space => {
        return space.id !== spaceUpdated.data.id ? space : {
          ...space,
          ...spaceUpdated.data
        }
      })
      await this.$store.dispatch('setSpaces', updatedSpaces)
      this.$toast.open({
        message: `Space updated!`,
        type: 'success',
        position: 'top-right'
      });

      this.loading = false;
    },
    formatTtl(ttl) {
      return parseInt(ttl.toString().replace(' Days', ''), 10)
    },
    validateTtl(ttl) {
      const num = parseInt(ttl.replace(' Days', ''), 10);
      if (num === 0) {
        return ttl = '1';
      }
      if (num >= 1000) {
        return ttl = '999';
      }
      return ttl
    },
    incrementTtl(module) {
      const num = this.formatTtl(module.ttl);
      if (num >= 999) return;
      module.ttl = `${num + 1}`;
    },
    decrementTtl(module) {
      const num = this.formatTtl(module.ttl);
      if (num <= 1) return;
      module.ttl = `${num - 1}`;
    },
  }
}
</script>

<style lang="scss">
.workflow-tables-wrap {
  .grid-item {
    display: grid !important;
    grid-template-columns: auto minmax(0, 1fr) auto auto;
  }

  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }

  .v-input--selection-controls__input {
    margin-right: 4px;
  }

  .workflow-tables-col {
    min-height: 296px;

    &:first-child {
      max-width: 340px;
    }
  }

  .workflow-table {
    border: 1px solid var(--v-gray-30-base);
    background-color: var(--v-gray-10-base);

    table {
      border-collapse: collapse;
    }

    .workflow-table-head {
      margin-bottom: 6px;

      .workflow-table-order {
        min-width: 71px;
      }
    }

    .workflow-table-duration {
      width: 108px;
    }

    .workflow-table-order {
      min-width: 44px;
    }

    .workflow-table-row-item {
      margin-bottom: 6px;
    }

    .workflow-item-index {
      min-width: 44px;
    }

    .workflow-item-move-back {
      width: 44px;
    }

    .workflow-ttl-input {
      input {
        height: 28px;
        padding: 9px;
        width: calc(36px + 8px * var(--value-length, 1));
        border: 1px solid var(--v-gray-30-base);
        outline: none;
        border-radius: 4px;
        font-size: 12px;
        color: var(--v-gray-80-base);
        background: var(--v-gray-10-base);
      }

      button {
        top: 4px;
        right: 2px;

        &:last-child {
          top: 14px;
        }
      }
    }

    .bordered-item {
      height: 44px;
      border: 1px solid var(--v-gray-30-base);
      background-color: var(--v-gray-0-base);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);

      &.empty-state {
        height: 100%;
      }
    }
  }

  .rename-input-error {
    font-size: 10px !important;
    white-space: break-spaces;
  }
}

.closed-workflow-tooltip {
  padding: 14px 16px 15px;
  font-size: 10px;
  line-height: 12px;
  box-shadow: 0 0 0 1px var(--v-gray-0-base), 0 10px 20px rgba(0, 0, 0, 0.05);

  &::before {
    content: '';
    position: absolute;
    bottom: -11px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 11px solid var(--v-gray-0-base);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--v-gray-80-base);
  }
}
</style>
